body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  text-align: center;
  background-color: #d3e1e5;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.component {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.description-text {
  color: #354e62;
  padding: 0em 2em;
}

.button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  text-align: center;
  letter-spacing: 0.1rem;
  background: rgb(255, 72, 86);
  padding: 1.1875rem 1.25rem 1rem;
  border-radius: 0.1875rem;
  transition: background 0.1s ease-in-out 0s;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 72, 86);
  border-image: none;
  border-image: initial;
  font-weight: bold;
}

.error-text {
  color: red;
}
