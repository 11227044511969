.component {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
}

.description-text {
  color: #354e62;
  padding: 0em 2em;
}

.button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: white;
  text-align: center;
  letter-spacing: 0.1rem;
  background: rgb(255, 72, 86);
  padding: 1.1875rem 1.25rem 1rem;
  border-radius: 0.1875rem;
  transition: background 0.1s ease-in-out 0s;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 72, 86);
  border-image: initial;
  font-weight: bold;
}

.error-text {
  color: red;
}